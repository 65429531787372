import { graphql } from "gatsby"
import React from "react"
import SEO from "../components/seo"

import Navbar from "../components/Menu/LifestyleNav"
import MarkdownViewer from "@components/MarkdownViewer"
import NuvoImage from "../components/NuvoImage"
import Timeline from "../components/Timeline/Timeline"
import TheaterVideo from "../components/Theater/TheaterVideo"
import { CallToAction } from "./lifestyle"
import { PrivacyFooter } from "./lifestyle"

const LifestyleExperiencePage = ({ data, location }) => {
  const {
    metaTitle,
    metaDescription,
    isRobotsIndex,
    hero,
    timeline,
    callToAction
  } = data.allUniquePagesJson.nodes[0]
  return (
    <>
      <SEO
        title={metaTitle}
        description={metaDescription}
        pathname={location.pathname}
        robots={!isRobotsIndex ? "noindex" : ""}
      />
      <Navbar location={location.pathname} />
      <div className="lifestyle buffer">
        <div className="ls__section">
          <div className="columns">
            <div className="column is-3"></div>
            <div className="column">
              <h1 className="has-text-centered">{hero.heading}</h1>
              <MarkdownViewer markdown={hero.blurb} />
            </div>
            <div className="column is-3"></div>
          </div>
        </div>

        <NuvoImage
          useAR
          cloudName="nuvolum"
          publicId={hero.youtube.image}
          width="auto"
          responsive>
          <div className="ls__hero-img-theater-overlay">
            <TheaterVideo
              videoUrl={`https://www.youtube.com/watch?v=${hero.youtube.code}`}
              controls
              playing
              buttonClass="ls__theater-btn"
            />
          </div>
        </NuvoImage>

        {timeline.hasThisSection && (
          <div className="ls__section">
            <div className="ls__timeline">
              <h2 className="has-text-centered">{timeline.heading}</h2>
              <Timeline items={timeline.items} />
            </div>
          </div>
        )}

        <CallToAction
          heading={callToAction.heading}
          blurb={callToAction.blurb}
          buttons={callToAction.buttons}
          colorBack
        />
      </div>
      <PrivacyFooter />
    </>
  )
}

export const lifestyleExperiencePageQuery = graphql`
  query lifestyleExperiencePage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        isRobotsIndex
        hero {
          image
          heading
          blurb
          youtube {
            code
            image
          }
        }
        timeline {
          hasThisSection
          heading
          items {
            startsVisible
            title
            content
          }
        }
        callToAction {
          heading
          blurb
          buttons {
            button {
              href
              appearance
              buttonText
              destination
            }
          }
        }
      }
    }
  }
`

export default LifestyleExperiencePage
